.triangle-node {
  background-color: "#9ca8b3";
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 0 50px 80px 50px;
  border-color: transparent transparent #9ca8b3 transparent;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}

.triangle-node-text {
  text-align: center;
  top: 40px;
  left: -23px;
  position: relative;
  width: 46px;
  height: 46px;
  margin: 0px;
}

.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 10px solid #555;
  border-radius: 5px;
  text-align: center;
}
.react-flow__nodes {
  box-sizing: border-box;
  border: none;
  padding: 0;
}
.react-flow__node {
  box-sizing: border-box;
  border: none;
  padding: 0;
}
.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 12px;
  text-align: center;
  padding: 10px;
}
.react-flow__node {
  border: none;
}

.react-flow__node .selected {
  border: 1px solid rgba(4, 106, 243, 1);
}
.react-flow__edge-path {
  stroke-width: 2;
}
.react-flow {
  /* border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; */
}
.providerflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.providerflow .reactflow-wrapper {
  flex-grow: 1;
}
