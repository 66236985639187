.treeselect .p-treeselect {
  display: flex;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  min-height: 2.4rem;
  width: min-content;
  max-width: 12rem;
  background: #ffffff;
  color: #0a043c;

  @media screen and (max-width: 820px) {
    max-width: 9rem;
  }
  @media screen and (max-width: 700px) {
    max-width: 7rem;
  }
  @media screen and (max-width: 600px) {
    max-width: 28rem;
  }
  @media screen and (max-width: 565px) {
    max-width: 26rem;
  }
  @media screen and (max-width: 525px) {
    max-width: 23rem;
  }
  @media screen and (max-width: 500px) {
    max-width: 27rem;
  }
  @media screen and (max-width: 475px) {
    max-width: 25rem;
  }
  @media screen and (max-width: 450px) {
    max-width: 23rem;
  }
  @media screen and (max-width: 395px) {
    max-width: 20.5rem;
  }
}

.p-treeselect-panel {
  @media screen and (max-width: 800px) {
    max-width: 70%;
    margin-left: 3rem;
  }
  @media screen and (max-width: 600px) {
    max-width: 70%;
    margin-left: 0.8rem;
  }
  @media screen and (max-width: 550px) {
    max-width: 70%;
    margin-left: 0.8rem;
  }
  @media screen and (max-width: 450px) {
    max-width: 50%;
    margin-left: 0rem;
  }
  @media screen and (max-width: 400px) {
    max-width: 30%;
    margin-left: 0rem;
  }
}

.p-treeselect-label {
  padding-left: 0 !important;
}

.dropdown .p-inputtext {
  display: flex !important;
  border: none !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.02em !important;
  min-height: 2.4rem !important;
  width: min-content !important;
  max-width: 12rem !important;
  background: #ffffff !important;
  color: #0a043c !important;
}
