/* .p-datatable-tbody {
} */
.p-datatable .p-datatable-wrapper {
    border-bottom-left-radius: 25px; 
    border-bottom-right-radius: 25px; 
}
.p-datatable .p-paginator-bottom {
    border-bottom-left-radius: 25px; 
    border-bottom-right-radius: 25px; 
}
