.chatBarHolder {
  position: relative;
  z-index: 9;


  @media (max-width: 1025px) {
   position: fixed;
   height: 100%;
  }
}

.openCloseButton {
  position: absolute;
  font-size: 16px !important;
  top: 40%;
  padding: 12px 6px 12px 6px;
  background: #2f343d !important;
  box-shadow: none;
}

.openCloseButtonChatInfos {
  position: absolute;
  font-size: 16px !important;
  top: 40%;
  right: 0;
  padding: 12px 6px 12px 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5 !important;
  border-right: none !important;
  background: white !important;
  color: #2f343d !important;
  box-shadow: none;
}

.openCloseButtonChatInfos:enabled:focus {
  box-shadow: none !important;
}

.openCloseButton:enabled:focus {
  box-shadow: none !important;
}
