.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

/* adicionar prefixo da classe do pai para não aplicar isso a todos os botões com icones */
.saveBTN .p-button-icon {
  position: relative !important;
  left: 5.2rem;
}

/* styling lateral bar from update colaborators screan */
.my_vb_list {
  max-height: 20rem;
  border: solid 1px gainsboro;
  border-radius: 10px;
  overflow: auto;
  padding: 8px;
  background-color: #2b282800;
  box-shadow: 0px 1px 5px -1px #212121;
}

.p-sidebar-content::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: #746c6cb5;
  background-clip: content-box;
}

.p-sidebar-content::-webkit-scrollbar {
  width: 20px;
}

.p-sidebar-content::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}
/* *********************** */

.addWspaceDialog {
  border-radius: unset !important;
  border: none !important;
}
.editBotDialog {
  border-radius: unset !important;
  border: none !important;
}
.addWspaceDialog .p-dialog-content {
  padding: 0rem !important;
  display: flex;
  justify-content: space-between;
}
.editBotDialog .p-dialog-content {
  padding: 0rem !important;
  display: flex;
  justify-content: space-between;
}

.addWspaceDialog .p-dialog-header {
  border-top-right-radius: 23px !important;
  border-top-left-radius: 23px !important;
  border-bottom: none !important;
}
.editBotDialog .p-dialog-header {
  border-top-right-radius: 23px !important;
  border-top-left-radius: 23px !important;
  background-color: #4a6690 !important;
  border-bottom: none !important;
}
.addWspaceDialog .p-dialog-header-icons {
  border: solid 2px;
  border-radius: 20px;
  color: white;
  position: relative;
  top: -7px;
  left: 5px;
}
.editBotDialog .p-dialog-header-icons {
  border: solid 2px;
  border-radius: 20px;
  color: white;
  position: relative;
  top: -7px;
  left: 5px;
}
.addWspaceDialog .p-dialog-header-icon {
  padding: 11px;
  color: aliceblue !important;
  height: 13px !important;
  width: 13px !important;
}
.editBotDialog .p-dialog-header-icon {
  padding: 11px;
  color: aliceblue;
  height: 13px;
  width: 13px;
}
.addWspaceDialog .pi {
  font-size: 0.9rem;
  margin-top: 1px;
  margin-left: 1px;
}
.editBotDialog .pi {
  font-size: 0.9rem;
  margin-top: 1px;
  margin-left: 1px;
}
.rigtContent {
  /* border: solid 1px; */
  width: 59%;
  position: absolute;
  height: 100%;
  top: 0px;
  border-radius: 20px;
  background-color: white;
  border-top-right-radius: 3.7rem;
}
.rigtContentFake {
  width: 58%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 1rem;
  padding-left: 3rem;
  display: flex;
  justify-content: center;
}
.leftContent {
  width: 41%;
  background-color: #ff0025;
}
.addWspaceDialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 23px !important;
}
.editBotDialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 23px !important;
}

/* ============================ css workspace scream ========================= */

.invitBtnDiv .p-button-label {
  text-align: initial;
}

.creatWorkspaceBtnform {
  width: 198px !important;
  height: 42px !important;
  border-radius: 8px !important;
  margin: auto !important;
  display: flex !important;
}
.panelWorkspace .p-tabview .p-tabview-panels {
  padding-top: 1rem !important;
}
.selectPlusButton {
  width: 45%;
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-radius: 0px !important;
  margin-left: 3px !important;
}
/* .selectPlusButton {
  border: none;
  left: 3px;
} */

.inviteContainer .inviteBtnCustom {
  height: 29px !important;
  position: relative !important;
  top: 4px !important;
  right: 4px !important;
  background-color: #d4e2f3 !important;
  color: #046af3 !important;
  border-color: white !important;
}

.btnPersonInvite {
  position: absolute;
  right: 6px;
  top: 5px;
  width: 26%;
}
.panelWorkspace .p-tabview-nav-content {
  border: solid 1px #c4ccd8 !important;
  border-radius: 6px !important;
}
.panelWorkspace .p-tabview-nav {
  display: flex;
  justify-content: flex-start;
}

.panelWorkspace ul .p-tabview-selected {
  border-bottom: solid 2px red !important;
  border-bottom-width: medium !important;
  position: relative !important;
  top: 1px !important;
  border-bottom-left-radius: 5px !important;
}

/* .panelWorkspace ul li:first-child .p-tabview-selected { */
/* border-right: solid 1px #dee2e6 !important; */
/* } */
/* .panelWorkspace ul li + li { */
/* border-left: solid 1px #dee2e6 !important; */
/* } */

.panelWorkspace ul li:first-child {
  width: 46%;
}
.panelWorkspace ul li + li {
  width: 54%;
}

.panelWorkspace ul li:first-child a {
  display: flex;
  justify-content: center;
  border-left: 0px !important;
}
.panelWorkspace ul li + li a {
  display: flex;
  justify-content: center;
  border-right: 0px !important;
}
.panelWorkspace .p-tabview-nav-link {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}
.Dt_listTeam .p-datatable-thead > tr > th {
  border: 0px !important;
}

.Dt_listTeam .p-datatable-tbody > tr > td {
  border: 0px !important;
}

.Dt_listTeam .p-datatable-wrapper {
  height: 10rem !important;
}

.panelWorkspace .p-datatable-wrapper {
  border-radius: 12px !important;
}
.noBorderRL {
  border-left: 0px !important;
  border-right: 0px !important;
}

.panelWorkspace .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

/* ======================== side bar dialog in workspace =========================== */

.wsDialog .p-sidebar-header {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  color: black;
  background: #f8f8f8;
  margin-bottom: 0.8rem;
}

.wsDialog .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

.wsDialog .p-sidebar-header {
  border-bottom: solid 1px #e0dddd !important;
}
.btnConfiWorkspace {
  border: 1px solid #c4ccd8 !important;
  height: 55px !important;
  font-size: 16px !important;
  color: black !important;
  border-radius: 6px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.redColorBtnConfig {
  border-left: solid 6px #ff0025 !important;
}
.redIconBTN {
  background-color: rgba(255, 0, 37, 0.1);
  padding: 8px;
  border-radius: 30px;
  color: red;
}

.blueColorBtnConfig {
  border-left: solid 6px #046af3 !important;
}
.blueIconBTN {
  background-color: rgba(4, 106, 243, 0.12);
  padding: 8px;
  border-radius: 30px;
  color: #046af3;
}
.btnAddDefault {
  box-shadow: 0px 4px 10px rgb(70 64 200 / 24%) !important;
  border-radius: 6px !important;
}
.colorWokspaceConfig {
  color: #0a043c !important;
  text-decoration: none;
}

/* ======================== Template criacao template =========================== */

.titleSteps {
  color: #0a043c;
  font-size: 1.02rem !important;
}

.blueShadow {
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 8px;
}

.channelItems {
  top: 5px;
  right: 5px;
}

.avatarLayaOne {
  align-items: center;
  background-color: white;
  border: 2px solid #d4e2f3;
  border-radius: 50%;
  display: flex;
  height: 91px;
  justify-content: center;
  width: 91px;
  top: -2rem;
}
.avatarLayaTwo {
  align-items: center;
  background-color: #d4e2f3;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 81px;
  justify-content: center;
  width: 81px;
}

.blocoOne {
  border: 1px solid #d2d2d2;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.iconChoseColor input {
  border-radius: 50% !important;
  position: relative !important;
  top: -5px !important;
  border: solid #c9c9c9 !important;
}
.blocTwo {
  border: solid 1px #d2d2d2;
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 80px;
}
.chatContentColor {
  border-radius: 20px 20px 20px 0px;
  padding: 12px;
  display: flex;
  height: 80%;
  margin-top: 0.5rem;
}
.iconChat {
  width: 19px;
  height: 26px;
}
.iconAvatarColor {
  border-radius: 50%;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}
.borderAvatarColor {
  border-radius: 3rem;
  padding: 4px;
  border-color: transparent;
  margin-top: 1.2rem;
  margin-left: 2rem;
}
.borderAvatarColorChat {
  top: 0rem;
  left: 0rem;
  border-radius: 3rem;
  padding: 4px;
  border-color: transparent;
}
.tamanhoDialog {
  width: 50vw;
  height: 36vw !important;
}
.tamanhoNormal {
  width: 50vw;
}

@media (max-width: 1366px) {
  .tamanhoNormal {
    width: 60vw;
  }
}

.p-colorpicker-panel {
  /* top: 219.771px !important; */
  /* 276.771px !important; */

  /*  */
}

/* ======================== css Sidebar =========================== */
.sidebarHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #02cdad;
}

.tamanho {
  position: absolute;
  height: 100vh;
  width: 80px;
  transition: width 250ms ease-in;
  background: #ffffff;
}

.tamanhoExpanded {
  position: absolute;
  height: 100vh;
  width: 240px;
  transition: width 250ms ease-out;
  overflow: hidden;
}

.hoverNavIcon {
  transition: transform 0.3s ease-in-out;
}

.hoverNavIcon:hover {
  transform: translateX(10px);
  width: 100%;
  color: rgb(33, 37, 41);
}

.fade-background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: #000000;
  opacity: 0.1;
}
/* ======================== css Sidebar Drawflow =========================== */

.tamanhoDf {
  height: 100vh;
  width: 80px !important;
  transition: width 250ms ease-in;
  background: #ffffff;
}

.tamanhoExpandedDf {
  height: 100vh;
  width: 240px !important;
  transition: width 250ms ease-out;
  overflow: hidden;
}
