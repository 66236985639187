/* .p-datatable-tbody {
} */
.p-column-filter-row .p-column-filter-element {
  width: unset;
}
.p-datatable-thead > tr > th {
  padding: 0.1rem 0.3rem;
}

.p-inputtext {
  font-size: small;
}

.p-datatable-table td {
  padding: 5px 10px !important;
  border: solid 1px #efefef !important;
  color: #6e6b7b !important;
}

.custom-table .p-datatable-tbody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #ffffff;
}

.p-datatable .p-datatable-tbody > tr.p-highlight:hover {
  background: #eaeaea;
}

.p-inputtext {
  font-size: 12px !important;
  color: #6e6b7b !important;
}

.refresh-button {
  color: #046af3;
}

.room-managment-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: 50px;
  border: none;
  background: white !important;
  color: #046af3 !important;
}

.room-icon {
  font-size: 21px;
}

.p-placeholder {
  line-height: 2.5 !important;
}