/* .p-dropdown .p-dropdown-trigger {
    color: #FFF;
} */
/* .p-dropdown-label .p-inputtext .p-placeholder span {
    color: #FFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
} */
/* .p-dropdown .p-dropdown-label.p-placeholder {
    color: #FFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
} */

/* .p-dropdown-panel .p-dropdown-items {
    background-color: #007bff;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
    background-color: #007bff;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #007bff;
    
} */
.p-multiselect-trigger-icon {
    color: #FFF;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #1085FF;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled) {
    background-color: #3A99FC;
}
.p-multiselect-label {
    display: flex;
    gap: 1rem;
    max-width: 300px;
}
.p-datepicker table td > span {
    border-radius: 50%;
}
.p-calendar .p-inputtext {
    color: #FFF;
    background-color: transparent;
    outline: 0;
    border: none;
}
.p-calendar {
    display: flex;
    flex-direction: row-reverse;
}
.p-calendar-w-btn-right .p-datepicker-trigger {
    /* background-color: transparent; */
    border: none;
    border-top-right-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 5px;
}